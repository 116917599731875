import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { it } from './utils/translations/it'
import { en } from './utils/translations/en'

export const languages: Record<string, string> = {
    en: 'english',
    it: 'italian'
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    it: {
        translation: {
            ...it
        }
    },
    en: {
        translation: {
            ...en
        }
    }
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: 'en',
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
