export const en = {
    routes: {
        about: 'About',
        rules: 'Rules',
        tickets: 'Tickets',
        media: 'Media',
        gallery: 'Gallery',
        contacts: 'Contacts',
        sponsors: 'Sponsors'
    },
    tickets: 'Buy your ticket Here',
    contacts: 'Want to become a sponsor? Contact Us',
    faq: {},
    home: {
        about: 'About championship',
        rules: 'Rules',
        tickets: 'Tickets',
        partners: 'Our partners',
        sponsors: 'Sponsors'
    },
    about: 'Pole Revolution Italy is an International Pole Art Championship organized by pole dance studio <a\n' +
        '                    href="//www.instagram.com/poledance_revolution_rome" target="_blank">“Pole\n' +
        '                    Revolution Roma”</a> and officially recognised by <a href="//www.posaworld.org/" target="_blank">POSA</a> (Pole\n' +
        '                    Sports &\n' +
        '                    Arts World Federation) as a qualifying competition for POSA World Pole Art Championship. The goal of\n' +
        '                    our event is to organize high quality level sportive competition, where each\n' +
        '                    athlete will be respected and feel comfortable, will find a friendly atmosphere, professional\n' +
        '                    judgment and efficient organization.We want to give the opportunity to both high level athletes to\n' +
        '                    compete for the place in a World Pole Art Championship and very beginners to do their first\n' +
        '                    experience and their first step in the world of pole art. We offer you a big stage in one of the\n' +
        '                    theaters of Rome, where you can demonstrate not just your sportive skills but also fully express\n' +
        '                    your artistic side. We are happy to invite all pole dancers of any age, nationality and gender to\n' +
        '                    take part in the championship in a great and historical city of Rome!'
}
