import React from 'react';
import { createCn } from 'bem-react-classname';

import './style.css';
import logo from './img/favicon215.png';
import { NavLink } from 'react-router-dom';

const cn = createCn('footer');

export const Footer = React.memo(() => {
    return (
        <div className={ cn() }>
            <div className={ cn('content') }>
                <div className={ cn('logo') }>
                    <NavLink to={ '/' }>
                        <img src={ logo } alt="logo"/>
                    </NavLink>
                </div>
                <div className={ cn('contacts') }>
                    <a href="mailto:polerevolutionitaly@gmail.com"
                       className={ cn('email') }>polerevolutionitaly@gmail.com</a>
                    <a href="//instagram.com/_u/polerevolutionroma" className={ cn('ig') }>@polerevolutionroma</a>
                </div>
            </div>
        </div>
    );
});
