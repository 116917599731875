import React from 'react';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { ScrollToTopButton } from '../../components/scrolltop';
import './style.css';

const withLayout = (WrappedComponent: any, noWrap = false) => {
    return (
        <>
            <Navbar/>
            <div className={ noWrap ? 'content' : '' }>
                <WrappedComponent/>
            </div>
            <ScrollToTopButton/>
            <Footer/>
        </>
    );
};

export default withLayout;
