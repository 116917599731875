import React, { FC, useCallback, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';

const cn = createCn('modal');

type ModalProps = {
    imgId: string;
    visible: boolean;
    images: any;
    toggleVisibleHandler: any;
};

export const Modal: FC<ModalProps> = ({ imgId, visible, images, toggleVisibleHandler }) => {
    const [currentIndex, setCurrentIndex] = useState(images.keys().indexOf(imgId));

    const imageClickHandler = useCallback(() => {
        toggleVisibleHandler();
    }, [])

    const switchImage = (nextImage = true) => {
        const curIndex = nextImage ?
            (currentIndex + 1) % images.keys().length :
            (currentIndex - 1 + images.keys().length) % images.keys().length;
        setCurrentIndex(curIndex);
    };

    useEffect(() => {
        if (imgId) {
            setCurrentIndex(images.keys().indexOf(imgId));
        }
    }, [imgId, images]);

    return (
        <div className={ cn({ visible: visible }) }>
            <div className={ cn('closer') } onClick={ imageClickHandler }></div>
            <div className={ cn('controls') }>
                <div className={ cn('arrow', { left: true }) } onClick={ () => {switchImage()} }/>
                <div className={ cn('arrow', { right: true }) } onClick={ () => {switchImage(false)} }/>
            </div>
            <div className={ cn('image') }>
                { currentIndex >= 0 &&
                    <img src={ images(images.keys()[currentIndex]) } alt={ images.keys()[currentIndex] }/>
                }
            </div>
        </div>
    );
};
