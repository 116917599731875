import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withLayout from './utils/with-layout';
import { Home } from './routes/home';
import { Faq } from './routes/faq';
import { Workplan } from './routes/workplan';
import { Rules } from './routes/rules';
import ScrollTop from './utils/scroll-top';
import { Sponsor } from './routes/sponsor';
import { Documents } from './routes/documents';

import './i18n';
import './App.css';

function App() {
    return (
        <div className="App">
            <ScrollTop/>
            <Routes>
                <Route path="/" element={ withLayout(Home, true) }/>
                <Route path="/faq" element={ withLayout(Faq, true) }/>
                <Route path="/workplan" element={ withLayout(Workplan, true) }/>
                <Route path="/rules" element={ withLayout(Rules, true) }/>
                <Route path="/documents" element={ withLayout(Documents, true) }/>
                <Route path="/sponsor/:id" element={ withLayout(Sponsor, true) }/>
            </Routes>
        </div>
    );
}

export default App;
