import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import './style.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const cn = createCn('rules');

export const Documents = () => {
    const { i18n } = useTranslation();
    const title = i18n.language === 'en' ? 'Running Order' : 'Running Order';
    const delega = '/pdf/Running_Order_Pole_Revolution_2024.pdf';

    return (
        <div className={ cn() }>
            <Title title={ title }/>
            <div>Running Order Pole Revolution 2024 - <a href={ delega } target={ '_blank' } download={'Running Order Pole Revolution 2024'}>download</a><br/><br/></div>
        </div>
    );
};
