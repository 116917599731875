import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';

const cn = createCn('shevron');

export const Shevron: FC = () => {
    return (
        <div className={ cn() }>
            <div className={cn('top')}></div>
            <div className={cn('middle')}></div>
            <div className={cn('bottom')}></div>
        </div>
    );
};
