import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';

import med from './img/media.jpg';
import med2 from './img/media2.jpg';

const cn = createCn('media');

const media = [
    {
        title: 'Pole dance, il campionato sbarca in un teatro romano',
        href: 'https://metronews.it/2023/10/04/pole-dance/',
        image: med2
    },
    {
        title: 'Sport e sensualità, tutte pazze per la pole dance',
        href: 'https://www.vanityfair.it/gallery/sport-e-sensualita-tutte-pazze-per-la-pole-dance',
        image: med
    }
];

export const Media = () => {
    return (
        <div className={ cn() } id={'media'}>
            { media.map((item) => {
                return <div className={ cn('item') }>
                    <div className={ cn('img') }>
                        <a href={ item.href }><img src={ item.image } alt={ item.title }/></a>
                    </div>
                    <div className={ cn('link') }>
                        <a href={ item.href }>{ item.title }</a>
                    </div>
                </div>
            }) }
        </div>
    );
};
