import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SPONSORS } from './sponsors';

const cn = createCn('sponsor');

type Sponsor = {
    title: string,
    text: string
}

export const Sponsor = () => {
    const { i18n } = useTranslation();
    const { id = 'def' } = useParams();
    const sponsors = SPONSORS as Record<string, Sponsor>
    const currentSponsor = sponsors[id] || sponsors['def'];
    const imagePath = `../sponsors/${ id }.png`;

    function createMarkup(string: any) {
        return { __html: string };
    }

    return (
        <div className={ cn() }>
            <Title title={ currentSponsor.title }/>
            <img src={ imagePath } alt=""/>
            <div className={ cn('content') }>
                <p dangerouslySetInnerHTML={ createMarkup(currentSponsor.text) }/>
            </div>
        </div>
    );
};
