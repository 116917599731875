import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import './style.css';
import { useTranslation } from 'react-i18next';

const cn = createCn('faq');

const faq = {
    'en': [
        {
            title: 'How to apply for the competition?',
            description: 'You have to download the Rules&amp;Regulations file where you can find all the information about\n' +
                '                    necessary documents, application fee and deadlines. After that you need to download and fill the\n' +
                '                    application form. Send your Application Form in PDF/WORD format together with other documents to <a\n' +
                '                        href="mailto:polerevolutionitaly@gmail.com">polerevolutionitaly@gmail.com</a>.'
        },
        {
            title: 'Do we accept the picture of the application form filled by hand?',
            description: 'No'
        },
        {
            title: 'How much is the application fee?',
            description: '<ul>\n' +
                '                    <li>70 euro – single categories till 1st of August; 80 euro – from 2nd of August</li>\n' +
                '                    <li>120 euro – double categories till 1st of August; 130 euro – from 2nd of August</li>\n' +
                '                    <li>60 euro – juniors till 1st of August; 70 euro – from 2nd of August</li>\n' +
                '                    <li>55 euro/person – groups till 1st of August; 65 euro/person – from 2nd of August (min. 3 persons,\n' +
                '                        max. 5 persons)\n' +
                '                    </li>\n' +
                '                </ul>\n' +
                '\n' +
                '                <p>For the athletes who want to participate in more than one category there is a discount of 10%.</p>'
        },
        {
            title: 'Which is the DEADLINE for the application?',
            description: '15 september 2023'
        },
        {
            title: 'Who will be qualified for the World Pole Art POSA Championship?',
            description: 'Only the winners (1st, 2nd and 3rd places) of AMATEURS and ELITE categories'
        },
        {
            title: 'Is it possible to qualify for the World Pole Art if I’m from another country?',
            description: 'Yes, it is possible! But before you have to check with your national POSA Federation if there is a\n' +
                '                    national qualification championship in your country. The countries which CAN’T be qualified in our\n' +
                '                    championship: France (only Elite categories), Hungary, USA, Greece, South Korea, Norway. The athlete\n' +
                '                    with the highest score for their country will qualify to the PSWC and the athlete with the second\n' +
                '                    and third highest score for their country will also receive a place at the PSWC.'
        },
        {
            title: 'Does my coach have to be subscribed for the competition?',
            description: 'Yes. Each coach has to be subscribed till 20 of September for getting a free pass coach.' +
                ' The free access is permitted for 1 coach per 5 athletes and any coach document (card, certificate,' +
                ' ecc.) has to be presented. After 20.09 the subscription fee for coaches is 15 euro.'
        },
        {
            title: 'When is the deadline for the music?',
            description: 'The music has to be sent in MP3 format till 20 of September named like' +
                ' SURNAME_NAME_CATEGORY to: <a' +
                ' href="mailto:polerevolutionitaly@gmail.com">polerevolutionitaly@gmail.com</a>. All athletes who' +
                ' will not respect this deadline will immediately get the <b>penalty of -3 points</b>!'
        },
        {
            title: 'Where can I get more information about POSA rules&amp;regulations?',
            description: '<a href={ pdfUrl } target={ \"_blank\" }>2024 POSA Rules Regulations</a>'
        }
    ],
    'it': [
        {
            title: 'Come posso iscrivermi alla competizione?',
            description: 'Devi scaricare il file del Regolamento dove troverai tutte le informazioni circa i documenti necessari, i costi di iscrizione e le deadlines. Fatto ciò dovrai scaricare e compilare il modulo di iscrizione. Invia il tuo Modulo di iscrizione esclusivamente in formato PDF/WORD insieme a tutti i documenti richiesti all\'indirizzo <a' +
                ' href="mailto:polerevolutionitaly@gmail.com">polerevolutionitaly@gmail.com</a>.'
        },
        {
            title: 'Accettate foto del modulo di iscrizione compilato a mano?',
            description: 'NO'
        },
        {
            title: 'Quali sono i costi di iscrizione?',
            description: '  <ul><li>Singoli: 70 euro fino al 1°Agosto - 80 euro dal 2 Agosto;</li>\n' +
                '  <li>Double: 120 euro fino al 1°Agosto - 130 euro dal 2 Agosto</li>\n' +
                '  <li>Juniores: 60 euro fino al 1°Agosto - 70 euro dal 2 Agosto</li>\n' +
                '  <li>Gruppi: 55 euro/a persona fino al 1°Agosto - 65 euro/a persona dal 2 Agosto (min. 3 persone, max. 5 persone)</li>\n' +
                '</ul>' +
                '  <p>Per le/gli atlete/i che decidano di partecipare in più di una categoria è previsto uno sconto del' +
                ' 10% sul costo totale.</p>\n'
        },
        {
            title: 'Qual è il TERMINE ULTIMO entro cui è possibile iscriversi?',
            description: '15 september 2023'
        },
        {
            title: 'Chi ha accesso alla qualifica diretta al World Pole Art POSA Championship?',
            description: 'Unicamente i vincitori (1°, 2° e 3° posto) delle categorie AMATORI e ELITE'
        },
        {
            title: 'Posso qualificarmi al World Pole Art se vengo da un Paese estero?',
            description: 'Si, è possibile! Ma prima di iscriverti devi verificare con la  tua Federazione nazionale POSA Federation se nel tuo Paese si svolga un campionato nazionale valevole per la qualifica. I Paesi i cui atleti NON possono qualificarsi nel nostro Campionato al momento sono: Francia (solo per la categoria Elite), Ungheria, USA, Grecia, Sud Corea, Norvegia. Le/gli atlet* che ottengono il miglior piazzamento (primo, secondo e terzo miglior punteggio) nel proprio Paese, anche se non sul podio, si qualificano di diritto al  PSWC.'
        },
        {
            title: 'La/il mi* coach deve registrarsi alla competizione?',
            description: 'Si. Ciascun coach deve registrarsi entro il 20 Settembre per ottenere un "pass coach" gratuito. Il pass è rilasciato ad 1 coach per 5 atlete/i dietro presentazione di un documento (tessera, certificato, ecc.) che attesti la propria qualifica di istruttore certificato. Dopo il 20.09 il pass per le/i coach sarà rilasciato a pagamento al costo di 15 euro.'
        },
        {
            title: 'Qual è il TERMINE ULTIMO entro cui inviare la musica?',
            description: 'La musica deve essere inviata in formato MP3 entro e non oltre il 20 settembre, il file deve  rinominato con indicazione di nome, cognome e categoria dell’atleta (es. MARIA_ROSSI_AMATORI 18-39) al seguente indirizzo: polerevolutionitaly@gmail.com\n' +
                'A tutte/i le/gli atlete/i che non rispetteranno il suddetto termine verrà immediatamente comminata' +
                ' una <b>penalità di -3 punti</b>!'
        },
        {
            title: 'Dove posso trovare maggiori informazioni circa il regolamento POSA?',
            description: '<a href={ pdfUrl } target={ \"_blank\" }>2024 POSA Rules Regulations</a>'
        }
    ]
}

export const Faq = () => {
    const { i18n } = useTranslation();
    const pdfUrl = i18n.language === 'en' ? './pdf/ENG_Rules.pdf' : './pdf/IT_Rules.pdf';
    const curFaq = faq[i18n.language === 'en' ? 'en' : 'it'];

    function createMarkup(string: any) {
        return { __html: string };
    }

    return (
        <div className={ cn() }>
            <Title title={ 'FAQ' }/>
            <div className={ cn('content') }>
                { curFaq.map((item: any) => {
                    return <>
                        <h3 dangerouslySetInnerHTML={ createMarkup(item.title) }/>
                        <p dangerouslySetInnerHTML={ createMarkup(item.description) }/>
                    </>
                }) }
            </div>
        </div>
    );
};
