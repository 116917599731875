import React, { useCallback, useState } from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/modal';

const cn = createCn('rules');

function setOverflow(flag: boolean) {
    const overflowValue = flag ? 'hidden' : '';
    document.documentElement.style.overflow = overflowValue;
    document.body.style.overflow = overflowValue;
}

export const Rules = () => {
    const { i18n } = useTranslation();
    const pdfUrl = i18n.language === 'en' ? './pdf/ENG_Rules.pdf' : './pdf/IT_Rules.pdf';

    const [visible, setVisible] = useState(false);
    const [imgId, setImgId] = useState('');
    const imagesEn = require.context('./img/en', false, /\.(png|jpe?g|gif|svg)$/);
    const imagesIt = require.context('./img/it', false, /\.(png|jpe?g|gif|svg)$/);
    const images = i18n.language === 'en' ? imagesEn : imagesIt;

    const imageClickHandler = useCallback((key: string) => {
        setImgId(key);
        setVisible(true);
        setOverflow(true);
    }, [])

    const toggleVisibleHandler = useCallback(() => {
        setVisible((prev) => !prev);
        setImgId('');
        setOverflow(visible);
    }, [visible])

    return (
        <div className={ cn() }>
            <Title title={ 'Rules & Regulations' }/>
            2024 POSA Rules Regulations - <a href={ pdfUrl } target={ '_blank' }>Download.pdf</a><br/><br/>
            <div className={ cn('content') }>
                { images.keys().map(key => (
                    <div className={ cn('item') } onClick={ () => imageClickHandler(key) }>
                        <img
                            key={ key }
                            src={ images(key) }
                            alt={ key }
                        />
                    </div>
                )) }
                <Modal imgId={ imgId } visible={ visible } images={ images }
                       toggleVisibleHandler={ toggleVisibleHandler }/>
            </div>
        </div>
    );
};
