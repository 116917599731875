import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import ticket from './img/tickets.png';
import './style.css';
import { useTranslation } from 'react-i18next';

const cn = createCn('tickets');

export const Tickets = () => {
    const { t, i18n } = useTranslation();

    function createMarkup(string: any) {
        return { __html: string };
    }

    return (
        <div className={ cn() } id={ 'tickets' }>
            <div className={ cn('img') }>
                <a href="//lc.cx/yM8cgJ" target={ '_blank' } rel="noreferrer"><img src={ ticket } alt=""/></a>
            </div>
            <a href="//lc.cx/yM8cgJ" target={ '_blank' } rel="noreferrer">
                <div className={ cn('link') } dangerouslySetInnerHTML={ createMarkup(t('tickets')) }/>
            </a>
        </div>
    );
};
