import React, { useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('scrolltop');

export const ScrollToTopButton = React.memo(() => {
    const [showButton, setShowButton] = useState(false);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setShowButton(true);
            }
            else {
                setShowButton(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={ cn() }>
            { showButton && <button onClick={ handleClick }/> }
        </div>
    );
});

