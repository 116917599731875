import React, { FC, useCallback, useState } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';
import { Modal } from '../../../../components/modal';

const cn = createCn('gallery');

function setOverflow(flag: boolean) {
    const overflowValue = flag ? 'hidden' : '';
    document.documentElement.style.overflow = overflowValue;
    document.body.style.overflow = overflowValue;
}

export const Gallery = () => {
    const [visible, setVisible] = useState(false);
    const [imgId, setImgId] = useState('');
    const images = require.context('./img', false, /\.(png|jpe?g|gif|svg)$/);

    const imageClickHandler = useCallback((key: string) => {
        setImgId(key);
        setVisible(true);
        setOverflow(true);
    }, [])

    const toggleVisibleHandler = useCallback(() => {
        setVisible((prev) => !prev);
        setImgId('');
        setOverflow(visible);
    }, [visible])

    return (
        <div className={ cn() } id={'gallery'}>
            { images.keys().map(key => (
                <div className={ cn('item') } onClick={ () => imageClickHandler(key) }>
                    <img
                        key={ key }
                        src={ images(key) }
                        alt={ key }
                    />
                </div>
            )) }
            <Modal imgId={ imgId } visible={ visible } images={ images }
                   toggleVisibleHandler={ toggleVisibleHandler }/>
        </div>
    );
};
