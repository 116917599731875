export const SPONSORS = {
    def: {
        title: 'Sponsor',
        text: 'Text'
    },
    opendance: {
        title: "OpenDance Academy",
        text: "Introducing Our Esteemed Sponsor: OpenDance Academy - Your Premier Online Destination for Pole Dancing Excellence<br/>" +
            "<br/>" +
            "Welcome to OpenDance Academy, the pinnacle of online pole dancing education. We take immense pride in our association with this exceptional sponsor. Our platform is dedicated to delivering top-tier pole dancing instruction, featuring renowned artists such as Evgeny Greshilov, Kira Noire, Marion Crampe, Maddie Sparkle, Dimitry Politov, Olga Trifonova, Natasha Wang, and many more!<br/>" +
            "<br/>" +
            "Why OpenDance Academy?<br/>" +
            "<br/>" +
            "Unparalleled Quality: Access the highest quality video tutorials available on the web.<br/>" +
            "Mobile Application: Our user-friendly app is readily available on both the App Store and Google Play, allowing you to build your dancing playlist on the go.<br/>" +
            "Dive into Diversity: Explore a wide array of styles, including exotic pole dancing, frame up, strip routines, men-only techniques, and unique single signature choreographies.<br/>" +
            "Your Dance Journey Awaits: Embark on the path to becoming the pole dancer you've always aspired to be.<br/>" +
            "<br/>" +
            "Join us at OpenDance Academy and elevate your pole dancing skills to new heights!"
    }
}
