export const it = {
    routes: {
        about: 'About',
        rules: 'Regolamento',
        tickets: 'Biglietti',
        media: 'Media',
        gallery: 'Galleria',
        contacts: 'Contatti',
        sponsors: 'Sponsors'
    },
    tickets: 'Acquista il tuo biglietto qui',
    contacts: 'Vuoi diventare nostro Sponsor?',
    faq: {},
    home: {
        about: 'Il campionato',
        rules: 'Regolamento e modulistica',
        tickets: 'Biglietti',
        partners: 'I nostri Partners',
        sponsors: 'Sponsors'
    },
    about: 'Pole Revolution Italy è un  Campionato Internazionale di Pole Art” organizzato dallo studio pole dance' +
        ' <a\n' +
        '                    href="//www.instagram.com/poledance_revolution_rome" target="_blank">“Pole\n' +
        '                    Revolution Roma”</a> ed ufficialmente riconosciuto da <a href="//www.posaworld.org/" target="_blank">POSA</a> (Pole Sports & Arts World Federation) come competizione valevole per la qualifica al POSA World Pole Art Championship.  L\'obiettivo del nostro evento è di realizzare una competizione sportiva di alto livello, nella quale ogni atleta potrà sentirsi rispettata/o ed a proprio agio e troverà un’atmosfera amichevole, un giudizio professionale ed un’organizzazione efficiente. Vogliamo offrire l\'opportunità alle atlete e atleti di alto livello di competere per accedere al World Pole Art Championship ed alle/i principianti di fare la loro prima esperienza ed il proprio ingresso nel mondo della pole art.\n Metteremo a tua disposizione il grande palcoscenico di uno storico teatro romano, dove potrai dimostrare non solo le tue abilità sportive ma anche esprimere appieno il tuo lato artistico.\n' +
        'Siamo felici di invitare le/gli atlete/i di pole dance di ogni età, nazionalità e genere a partecipare al nostro Campionato nella grandiosa e storica cornice della città di Roma!\n'
}
