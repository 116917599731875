import React, { FC, useCallback, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';

const cn = createCn('partners');

export const Partners = () => {
    const images = require.context('./img', false, /\.(png|jpe?g|gif|svg)$/);

    return (
        <div className={ cn() }>
            { images.keys().map(key => (
                <div className={ cn('item') }>
                    <img
                        key={ key }
                        src={ images(key) }
                        alt={ key }
                    />
                </div>
            )) }
        </div>
    );
};
