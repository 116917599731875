import React from 'react';
import { createCn } from 'bem-react-classname';
import { Shevron } from '../../components/shevron';
import { Tickets } from './components/tickets';
import { Media } from './components/media';
import { Gallery } from './components/gallery';
import { Partners } from './components/partners';
import { Contact } from './components/form';
import { Title } from '../../components/title';
import { useTranslation } from 'react-i18next';

import './style.css';

import banner from './img/banner.png';
import { NavLink } from 'react-router-dom';
// import { Sponsors } from './components/sponsors';

const cn = createCn('home');

export const Home = React.memo(() => {
    const { t, i18n } = useTranslation();
    const images = require.context('./img/icons', true);

    const benefits = [
        {
            title: i18n.language === 'en'
                ? 'Rules & Regulations'
                : 'Regolamento',
            description: '',
            href: '/rules'
        },
        {
            title: 'Running Order',
            description: '',
            href: '/documents',
            download: false
        },
        {
            title: 'Workplan',
            description: '',
            href: '/workplan'
        },
        {
            title: 'FAQ',
            description: '',
            href: '/faq'
        }
    ];

    function createMarkup(string: any) {
        return { __html: string };
    }

    return (
        <div className={ cn() } id={ 'home' }>
            <div className={ cn('banner') }>
                <div className={ cn({ img: true }) }>
                    <img src={ banner } alt=""/>
                </div>
            </div>
            <div className={ cn('content') }>
                <div className={ cn('sub_title') }><Title title={ t('about', { keyPrefix: 'home' }) }/></div>
                <div className={ cn('about') } id={ 'about' }>
                    <div dangerouslySetInnerHTML={ createMarkup(t('about')) }/>
                </div>
                <Shevron/>
                <div className={ cn('benefits') } id={ 'rules' }>
                    { benefits.map((item, index) =>
                        <div className={ cn('benefit') }>
                            <div className={ cn('icon') }>
                                { item.download ?
                                    <a href={ item.href } target={ '_blank' } download rel="noreferrer"><img key={ index } src={ images(
                                        `./icon${ index }.png`) }/></a> :
                                    <NavLink to={ item.href }><img key={ index }
                                                                   src={ images(`./icon${ index }.png`) }/></NavLink>
                                }
                            </div>
                            <div className={ cn('description') }>
                                <div className={ cn('title') }>
                                    { item.download ?
                                        <a href={ item.href } target={ '_blank' } download rel="noreferrer">{ item.title }</a> :
                                        <NavLink to={ item.href }>{ item.title }</NavLink>
                                    }
                                </div>
                            </div>
                        </div>
                    ) }
                </div>
                <Shevron/>
                <h2>{ t('tickets', { keyPrefix: 'home' }) }</h2>
                <Tickets/>
                <Shevron/>
                <h2>Media</h2>
                <Media/>
                <Shevron/>
                <h2>{ t('gallery', { keyPrefix: 'routes' }) }</h2>
                <Gallery/>
                <Shevron/>
                <h2>{ t('partners', { keyPrefix: 'home' }) }</h2>
                <Partners/>
                {/*<h2>{ t('sponsors', { keyPrefix: 'home' }) }</h2>*/}
                {/*<Sponsors/>*/}
                <Shevron/>
                <h2>{ t('contacts') }</h2>
                <Contact/>
            </div>
        </div>
    );
});
