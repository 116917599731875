import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';
import { languages } from '../../i18n';
import { useTranslation } from 'react-i18next';

import './style.css';
import logo from './img/favicon215.png';
import useMedia from '../../utils/use-media';
import { tabletSmall } from '../../constants';

const cn = createCn('navbar');
const NAV_ARR = ['rules', 'tickets', 'media', 'gallery', 'contacts'];

export const Navbar = React.memo(() => {
    const [current, setCurrent] = useState('');
    const { t, i18n } = useTranslation();
    const isTabletSmall = useMedia(tabletSmall);
    const [burgerVisible, setBurgerVisible] = useState(false);
    const menuRef = useRef(null);

    const NAV_ITEMS = NAV_ARR.map(item => {
        return { id: item, label: t(item, { keyPrefix: 'routes' }) }
    })

    const handleLangClick = useCallback((lang: string) => {
        i18n.changeLanguage(lang);
    }, []);

    const handleBurgerClick = useCallback(() => {
        setBurgerVisible(!burgerVisible);
    }, [burgerVisible]);

    const closeBurgerModal = useCallback(() => {
        setBurgerVisible(false);
    }, []);

    useEffect(() => {
        const onHashChange = () => {
            const { hash } = window.location;
            const element = document.querySelector(hash);
            if (element) {
                const elementRect = element.getBoundingClientRect();
                const offsetTop = elementRect.top + window.pageYOffset;
                window.scrollTo({
                    top: offsetTop - 200,
                    behavior: 'smooth'
                });
                setCurrent(hash);
            }
        };

        window.addEventListener('hashchange', onHashChange);

        return () => {
            window.removeEventListener('hashchange', onHashChange);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // @ts-ignore
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setBurgerVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [burgerVisible]);

    return (
        <div className={ cn() }>
            <div className={ cn('content') }>
                <div className={ cn('logo') }>
                    <a href={ `/#home` }>
                        <img src={ logo } alt="logo"/>
                    </a>
                </div>

                { !isTabletSmall &&
                    <div className={ cn('links') }>
                        { NAV_ITEMS.map(({ id, label }) => (
                            <a href={ `/#${ id }` } className={ `#${ id }` === current ? 'active' : '' }>
                                { label }
                            </a>
                        )) }
                    </div>
                }

                <div className={ cn('lang') }>
                    { Object.keys(languages)
                    .map((lang) =>
                        <div className={ cn('lang_item', { active: i18n.language === lang }) }
                             onClick={ () => handleLangClick(lang) }>
                            { lang }
                        </div>
                    ) }
                </div>

                { isTabletSmall &&
                    <div className={ cn('burger') } ref={ menuRef }>
                        <button onClick={ handleBurgerClick } className={ cn('burger_button') }>&nbsp;</button>
                        <div className={ cn('burger_modal', { visible: burgerVisible }) }>
                            { isTabletSmall &&
                                <>
                                    { NAV_ITEMS.map(({ id, label }) => (
                                        <div className={ cn('burger_item') }>
                                            <a href={ `/#${ id }` } className={ `#${ id }` === current ? 'active' : '' } onClick={ closeBurgerModal }>
                                                { label }
                                            </a>
                                        </div>
                                    )) }
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});
