import React, { SyntheticEvent, useCallback, useState } from 'react';
import axios from 'axios';
import { createCn } from 'bem-react-classname';
import './style.css';

const cn = createCn('contact');

export const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showForm, setShowForm] = useState(true);

    const handleSubmit = useCallback((event: SyntheticEvent) => {
        event.preventDefault();

        const bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('message', message);

        axios({
            method: 'post',
            url: './mail.php',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response: any) => {
            console.log(response);

            setName('');
            setEmail('');
            setMessage('');
            setShowForm(false);
        })
        .catch((error: any) => {
            console.error(error);
        });
    }, [name, email, message]);

    return (
        <div className={ cn() } id={ 'contacts' }>
            { showForm ?
                <form onSubmit={ handleSubmit }>
                    <div>
                        <input
                            type="text"
                            id="name"
                            value={ name }
                            onChange={ (event) => setName(event.target.value) }
                            placeholder={ 'Name' }
                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            id="email"
                            value={ email }
                            onChange={ (event) => setEmail(event.target.value) }
                            placeholder={ 'Email' }
                        />
                    </div>
                    <div>
                    <textarea
                        id="message"
                        value={ message }
                        onChange={ (event) => setMessage(event.target.value) }
                        placeholder={ 'Your message' }
                    />
                    </div>
                    <button type="submit">Send</button>
                </form> :
                <div>Your message was successfully sent!</div>
            }

        </div>
    );
};
